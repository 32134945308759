import firebase from 'firebase'

class Fire {
    constructor () {
        this.init()
        this.checkAuth()
    }

    init = () => {
        if (!firebase.apps.length) {
                firebase.initializeApp({
                    apiKey: "AIzaSyAPWpGdv7Lv4Aehant0Mnh4Jutv0On7Ixo",
                    authDomain: "brousseauchat.firebaseapp.com",
                    databaseURL: "https://brousseauchat.firebaseio.com",
                    projectId: "brousseauchat",
                    storageBucket: "brousseauchat.appspot.com",
                    messagingSenderId: "1004309121321",
                    appId: "1:1004309121321:web:1e7d8c23ee1aadf7890f38",
                    measurementId: "G-DEQD2PNPX1"

            })
        }
    };

    checkAuth = () => {
        firebase.auth().onAuthStateChanged(user => {
            if (!user) {
                firebase.auth().signInAnonymously();
            }
        });
    };

    send = messages => {
        messages.forEach(item => {
            const message = {
                text: item.text,
                timestamp: firebase.database.ServerValue.TIMESTAMP,
                user: item.user
            };

            this.db.push(message);
        });
    };

    parse = message => {
        const {user, text, timestamp} = message.val()
        const {key: _id} = message
        const createdAt = new Date(timestamp)

        return {
            _id,
            createdAt,
            text,
            user
        };
    };

    get = callback => {
        this.db.on('child_added', snapshot => callback(this.parse(snapshot)));
    };

    off() {
        this.db.off()
    }

    get db() {
        return firebase.database().ref("messages");
    }

    get uid() {
        return (firebase.auth().currentUser || {}).uid;
    }
}

export default new Fire();